import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Dashboard = () => {
  return (
    <Container>
      <div className="container">
        <div className="heading1">Automate AI Dashboard</div>

        <CardGroup style={{ width: '45rem', margin: '20px', padding: '20px' }}>
          <Card>
            <Card.Header>
              <Card.Title>Enviromental Variables</Card.Title>
            </Card.Header>
            <Card.Body>
              <Card.Text>Change all common environmental Variables in Automate-AI</Card.Text>
            </Card.Body>
            <Card.Footer>
              <Link to="/page1">
                <Button variant="primary">Click</Button>
              </Link>
            </Card.Footer>
          </Card>
          <Card>
            <Card.Header>
              <Card.Title>Enviromental Variables</Card.Title>
            </Card.Header>
            <Card.Body>
              <Card.Text>Change each organizations  environmental Variables in Automate-AI</Card.Text>
            </Card.Body>
            <Card.Footer>
              <Link to="/orgspage">
                <Button variant="primary">Click</Button>
              </Link>
            </Card.Footer>
          </Card>


          <Card>
            <Card.Header>
              <Card.Title>Create Model</Card.Title>
            </Card.Header>
            <Card.Body>
              <Card.Text>Create New Model</Card.Text>
            </Card.Body>
            <Card.Footer>
              <Link to="/page3">
                <Button variant="primary">Click</Button>
              </Link>
            </Card.Footer>
          </Card>

          <Card>
            <Card.Header>
              <Card.Title>Execute Workflow</Card.Title>
            </Card.Header>
            <Card.Body>
              <Card.Text>Execute Workflow for each organization</Card.Text>
            </Card.Body>
            <Card.Footer>
              <Link to="/page2">
                <Button variant="primary">Click</Button>
              </Link>
            </Card.Footer>
          </Card>

          <Card>
            <Card.Header>
              <Card.Title>LLM</Card.Title>
            </Card.Header>
            <Card.Body>
              <Card.Text>Create LLM</Card.Text>
            </Card.Body>
            <Card.Footer>
              <Link to="/page4">
                <Button variant="primary">Click</Button>
              </Link>
            </Card.Footer>
          </Card>
        </CardGroup>
      </div>
    </Container>
  );
};

export default Dashboard;
