import React, { useEffect, useState } from 'react';
import { BsArrowLeft } from 'react-icons/bs';
import { Tab, Nav, Table, Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Page4 = () => {
  const [selectedTab, setSelectedTab] = useState('llm');
  const [table1Data, setTable1Data] = useState([]);
  const [table2Data, setTable2Data] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [editingItem, setEditingItem] = useState({});
  const [mode, setModeValue] = useState('');
  //PopUp
  const [showLlmPopup, setShowLlmPopup] = useState(false);
  const [showLlmTypePopup, setShowLlmTypePopup] = useState(false);

  var baseurl = process.env.REACT_APP_API_URL;
  const idToken = localStorage.getItem('token');
  useEffect(() => {
    fetchData();
  }, [selectedTab]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };


  //Button when update button is clicked 
  const updateDataInTable = (type, item, mode) => {

    setModeValue(mode);
    if (type === 'llm') {
      if (mode === 'update' || mode === 'delete') {
        setEditingItem(item);
        setShowLlmPopup(true);
        setShowLlmTypePopup(false);

      } else {
        setEditingItem({ id: "", desc:" ",prompt: {"model":"gpt-3.5-turbo","prompt":"$","temperature":1}, modelId: "", llmType: "", active: "true" });
        setShowLlmPopup(true);
        setShowLlmTypePopup(false);
      }
    }
    if (type === 'llmType') {
      if (mode === 'update' || mode === 'delete') {
        setEditingItem(item);
        setShowLlmPopup(false);
        setShowLlmTypePopup(true);
      } else {
        setEditingItem({ id: "", llmType: "", active: "true" });
        setShowLlmPopup(false);
        setShowLlmTypePopup(true);
      }
    }
  };



  //Button when save button is clicked 
  const saveUpdate = async (type, value, mode) => {
    // Perform the update logic here with the provided parameters
    if (mode === 'create') {
      await createData(type, value); //API to update data 
    } else if (mode === 'update') {
      await updateData(type, value); //API to update data 
    } else {
      await deleteData(type, value); //API to update data 
    }

    // await fetchData();
    setEditingItem({});
    if (type === 'llm') {
      await fetchData();
      setShowLlmPopup(false);

    }
    if (type === 'llmType') {
      await fetchData();
      setShowLlmTypePopup(false);
    }
  };

  const fetchData = async () => {
    try {
      if (selectedTab === 'llm') {
        const response1 = await fetch(baseurl + 'llm', {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        });
        const jsonData1 = await response1.json();
        setTable1Data(jsonData1);
      } else if (selectedTab === 'llmType') {
        const response2 = await fetch(baseurl + 'llmType', {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        });
        const jsonData2 = await response2.json();
        setTable2Data(jsonData2);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };




  //API to Update 

  const updateData = async (type, data) => {
    try {
      const response = await fetch(baseurl + type + "/" + data.id, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`
        }
      });

      const result = await response.json();
      console.log('Response:', result);
    } catch (error) {
      console.error('Error:', error);
    }

  }

  //API to Create

  const createData = async (type, data) => {
    try {
      const response = await fetch(baseurl + type, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`
        }
      });
      const result = await response.json();
      console.log('Response:', result);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  //API to Delete

  const deleteData = async (type, data) => {
    try {
      const response = await fetch(baseurl + type + "/" + data.id, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`
        }
      });
      const result = await response.json();
      console.log('Response:', result);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  return (
    <div className="container">
      <Link to="/dashboard">
        <BsArrowLeft /> Back
      </Link>
      <div className="heading1">llm Setting </div>
      <Tab.Container activeKey={selectedTab} onSelect={handleTabClick}>

        <Nav variant="tabs">
          <Nav.Item>
            <Nav.Link eventKey="llm">llm</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="llmType">llm Type</Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content>
          <Tab.Pane eventKey="llm">
            <h4>Default LLM </h4>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th >ID</th>
                  <th >Desc</th>  
                  <th > prompt </th>
                  <th >Model Name </th>
                  <th >Update</th>
                  <th >Delete</th>
                </tr>
              </thead>
              <tbody>
                {table1Data.map((item) => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.desc}</td>
                    <td>{JSON.stringify(item.prompt)}</td>
                    <td>{item.model_name}</td>
                    <td>
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() => updateDataInTable("llm", item, "update")}
                      >
                        Update
                      </button>
                    </td>
                    <td>
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() => updateDataInTable("llm", item, "delete")}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td>
                    <Button id="department-add" variant="primary"
                      onClick={() => updateDataInTable("llm", {}, "create")}
                    >Add Row</Button>
                  </td>
                </tr>
              </tfoot>
            </Table>
            {showLlmPopup && (
              <Modal show={showLlmPopup} onHide={() => setShowLlmPopup(false)}>
                <Modal.Header closeButton>
                  <Modal.Title>{mode} Item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>ID: {editingItem.id}</p>
                  <p>Desc: <input  value={editingItem.desc}
                    onChange={(e) =>
                      setEditingItem({ id: editingItem.id, desc: e.target.value ,prompt:  editingItem.prompt, active: editingItem.active })
                    } />
                  </p>
                  <p>prompt: <textarea  value={JSON.stringify(editingItem.prompt)}
                    onChange={(e) =>
                      setEditingItem({ id: editingItem.id, desc: editingItem.desc , prompt: JSON.parse(e.target.value), active: editingItem.active })
                    } />
                  </p>
                  <p>Active: <input type="text" value={editingItem.active}
                    onChange={(e) =>
                      setEditingItem({ id: editingItem.id, desc: editingItem.desc , prompt: editingItem.prompt, active: e.target.value })
                    } />
                  </p>

                  {/* Add input fields for editing the values */}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => setShowLlmPopup(false)}>
                    Cancel
                  </Button>
                  <Button variant="primary" onClick={() => saveUpdate("llm", editingItem, mode)}>
                    {mode}
                  </Button>
                </Modal.Footer>
              </Modal>
            )}

          </Tab.Pane>
          <Tab.Pane eventKey="llmType">
            <h3>Model Type</h3>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Model Type </th>
                  <th> Active </th>
                  <th >Update</th>
                  <th >Delete</th>
                </tr>
              </thead>
              <tbody>
                {table2Data.map((item) => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.model_type}</td>
                    <td>{item.active}</td>
                    <td>
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() => updateDataInTable("llmType", item, "update")}
                      >
                        Update
                      </button>
                    </td>
                    <td>
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() => updateDataInTable("llmType", item, "delete")}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td>
                    <Button id="department-add" variant="primary"
                      onClick={() => updateDataInTable("llmType", {}, "create")}
                    >Add Row</Button>
                  </td>
                </tr>
              </tfoot>
            </Table>
            {showLlmTypePopup && (

              <Modal show={showLlmTypePopup} onHide={() => setShowLlmTypePopup(false)}>
                <Modal.Header closeButton>
                  <Modal.Title>{mode} Item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>ID: {editingItem.id}</p>
                  <p> Media Type:
                    <input type="text" value={editingItem.media_type}
                      onChange={(e) =>
                        setEditingItem({ id: editingItem.id, media_type: e.target.value, active: editingItem.active })
                      } />
                  </p>
                  <p>  Active :
                    <input type="text" value={editingItem.active}
                      onChange={(e) =>
                        setEditingItem({ id: editingItem.id, media_type: editingItem.media_type, active: e.target.value })
                      } />
                  </p>


                  {/* Add input fields for editing the values */}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => setShowLlmTypePopup(false)}>
                    Cancel
                  </Button>
                  <Button variant="primary" onClick={() => saveUpdate("llmType", editingItem, mode)}>
                    {mode}
                  </Button>
                </Modal.Footer>
              </Modal>
            )}
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};
export default Page4;