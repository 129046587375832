import React, { useEffect, useState } from 'react';
import { BsArrowLeft } from 'react-icons/bs';
import { Tab, Nav, Table, Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Page3 = () => {
  const [selectedTab, setSelectedTab] = useState('model');
  const [table1Data, setTable1Data] = useState([]);
  const [table2Data, setTable2Data] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [editingItem, setEditingItem] = useState({});
  const [mode, setModeValue] = useState('');
  //PopUp
  const [showMediaPopup, setShowMediaPopup] = useState(false);
  const [showMediaTypePopup, setShowMediaTypePopup] = useState(false);

  var baseurl = process.env.REACT_APP_API_URL;
  const idToken = localStorage.getItem('token');
  useEffect(() => {
    fetchData();
  }, [selectedTab]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };


  //Button when update button is clicked 
  const updateDataInTable = (type, item, mode) => {

    setModeValue(mode);
    if (type === 'model') {
      if (mode === 'update' || mode === 'delete') {
        setEditingItem(item);
        setShowMediaPopup(true);
        setShowMediaTypePopup(false);

      } else {
        setEditingItem({ id: "", industry_name: "", gross_margin: "", inventory_turnover: "", new_inventory_turnover: "", active: "true" });
        setShowMediaPopup(true);
        setShowMediaTypePopup(false);
      }
    }
    if (type === 'modeltype') {
      if (mode === 'update' || mode === 'delete') {
        setEditingItem(item);
        setShowMediaPopup(false);
        setShowMediaTypePopup(true);
      } else {
        setEditingItem({ id: "", department_name: "", department_description: "", active: "true", industryId: "" });
        setShowMediaPopup(false);
        setShowMediaTypePopup(true);
      }
    }
  };



  //Button when save button is clicked 
  const saveUpdate = async (type, value, mode) => {
    // Perform the update logic here with the provided parameters
    if (mode === 'create') {
      await createData(type, value); //API to update data 
    } else if (mode === 'update') {
      await updateData(type, value); //API to update data 
    } else {
      await deleteData(type, value); //API to update data 
    }

    // await fetchData();
    setEditingItem({});
    if (type === 'model') {
      await fetchData();
      setShowMediaPopup(false);

    }
    if (type === 'mediatype') {
      await fetchData();
      setShowMediaTypePopup(false);
    }
  };

  const fetchData = async () => {
    try {
      if (selectedTab === 'model') {
        const response1 = await fetch(baseurl + 'model', {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        });
        const jsonData1 = await response1.json();
        setTable1Data(jsonData1);
      } else if (selectedTab === 'mediatype') {
        const response2 = await fetch(baseurl + 'mediatype', {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        });
        const jsonData2 = await response2.json();
        setTable2Data(jsonData2);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };




  //API to Update 

  const updateData = async (type, data) => {
    try {
      const response = await fetch(baseurl + type + "/" + data.id, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`
        }
      });

      const result = await response.json();
      console.log('Response:', result);
    } catch (error) {
      console.error('Error:', error);
    }

  }

  //API to Create

  const createData = async (type, data) => {
    try {
      const response = await fetch(baseurl + type, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`
        }
      });
      const result = await response.json();
      console.log('Response:', result);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  //API to Delete

  const deleteData = async (type, data) => {
    try {
      const response = await fetch(baseurl + type + "/" + data.id, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`
        }
      });
      const result = await response.json();
      console.log('Response:', result);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  return (
    <div className="container">
      <Link to="/dashboard">
        <BsArrowLeft /> Back
      </Link>
      <div className="heading1">model Setting </div>
      <Tab.Container activeKey={selectedTab} onSelect={handleTabClick}>

        <Nav variant="tabs">
          <Nav.Item>
            <Nav.Link eventKey="model">model</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="mediatype">model Type</Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content>
          <Tab.Pane eventKey="model">
            <h4>Industry</h4>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th >ID</th>
                  <th >Model Name</th>
                  <th >Model HTML</th>
                  <th >Active</th>
                  <th >Model Type</th>
                  <th >model Path</th>
                  <th >Update</th>
                  <th >Delete</th>
                </tr>
              </thead>
              <tbody>
                {table1Data.map((item) => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.model_name}</td>
                    <td>{item.model_html}</td>
                    <td>{item.active}</td>
                    <td>{item.modelType}</td>
                    <td>{item.model_path}</td>
                    <td>
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() => updateDataInTable("model", item, "update")}
                      >
                        Update
                      </button>
                    </td>
                    <td>
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() => updateDataInTable("model", item, "delete")}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td>
                    <Button id="department-add" variant="primary"
                      onClick={() => updateDataInTable("model", {}, "create")}
                    >Add Row</Button>
                  </td>
                </tr>
              </tfoot>
            </Table>
            {showMediaPopup && (
              <Modal show={showMediaPopup} onHide={() => setShowMediaPopup(false)}>
                <Modal.Header closeButton>
                  <Modal.Title>{mode} Item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>ID: {editingItem.id}</p>
                  <p>Model Name: <input type="text" value={editingItem.model_name}
                    onChange={(e) =>
                      setEditingItem({ id: editingItem.id, model_name: e.target.value, active: editingItem.active, model_html: editingItem.model_html, modelType: editingItem.modelType, model_path: editingItem.model_path })
                    } />
                  </p>
                  <p>Model HTML:
                    <input type="text" value={editingItem.model_html}
                      onChange={(e) =>
                        setEditingItem({ id: editingItem.id, model_name: editingItem.model_name, active: editingItem.active, model_html: e.target.value, modelType: editingItem.modelType, model_path: editingItem.model_path })
                      } />
                  </p>
                  <p>Model Type:  <input type="text" value={editingItem.modelType}
                    onChange={(e) =>
                      setEditingItem({ id: editingItem.id, model_name: editingItem.model_name, active: editingItem.active, model_html: editingItem.model_html, modelType: e.target.value, model_path: editingItem.model_path })
                    }
                  /></p>
                  <p>Model Path:  <input type="text" value={editingItem.model_path}
                    onChange={(e) =>
                      setEditingItem({ id: editingItem.id, model_name: editingItem.model_name, active: editingItem.active, model_html: editingItem.model_html, modelType: editingItem.modelType, model_path: e.target.value })
                    }
                  /></p>
                  <p>Active <input type="text" value={editingItem.active}
                    onChange={(e) =>
                      setEditingItem({ id: editingItem.id, model_name: editingItem.model_name, active: e.target.value, model_html: editingItem.model_html, modelType: editingItem.modelType, model_path: editingItem.model_path })
                    } />
                  </p>
                  {/* Add input fields for editing the values */}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => setShowMediaPopup(false)}>
                    Cancel
                  </Button>
                  <Button variant="primary" onClick={() => saveUpdate("model", editingItem, mode)}>
                    {mode}
                  </Button>
                </Modal.Footer>
              </Modal>
            )}

          </Tab.Pane>
          <Tab.Pane eventKey="mediatype">
            <h3>Department</h3>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Department Name</th>
                  <th>Department Description</th>
                  <th>Industry ID</th>
                  <th >Update</th>
                  <th >Delete</th>
                </tr>
              </thead>
              <tbody>
                {table2Data.map((item) => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.department_name}</td>
                    <td>{item.department_description}</td>
                    <td>{item.industryId}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td>
                    <Button id="department-add" variant="primary"
                      onClick={() => updateDataInTable("mediatype", {}, "create")}
                    >Add Row</Button>
                  </td>
                </tr>
              </tfoot>
            </Table>
            {showMediaTypePopup && (

              <Modal show={showMediaTypePopup} onHide={() => setShowMediaTypePopup(false)}>
                <Modal.Header closeButton>
                  <Modal.Title>{mode} Item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>ID: {editingItem.id}</p>
                  <p>Department Name:
                    <textarea type="text" value={editingItem.department_name}
                      onChange={(e) =>
                        setEditingItem({ id: editingItem.id, department_name: e.target.value, department_description: editingItem.department_description, active: editingItem.active, industryId: editingItem.industryId })
                      } />
                  </p>
                  <p>Department Description:
                    <textarea type="text" value={editingItem.department_description}
                      onChange={(e) =>
                        setEditingItem({ id: editingItem.id, department_name: editingItem.department_name, department_description: e.target.value, active: editingItem.active, industryId: editingItem.industryId })
                      } />
                  </p>

                  <p>Industry:
                    <input type="text" value={editingItem.industryId}
                      onChange={(e) =>
                        setEditingItem({ id: editingItem.id, department_name: editingItem.department_name, department_description: editingItem.department_description, active: editingItem.active, industryId: e.target.value })
                      } />
                  </p>


                  <p>Active <input type="text" value={editingItem.active}
                    onChange={(e) =>
                      setEditingItem({ id: editingItem.id, department_name: editingItem.department_name, department_description: editingItem.department_description, active: e.target.value, industryId: editingItem.industryId })
                    } />
                  </p>


                  {/* Add input fields for editing the values */}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => setShowMediaTypePopup(false)}>
                    Cancel
                  </Button>
                  <Button variant="primary" onClick={() => saveUpdate("mediatype", editingItem, mode)}>
                    {mode}
                  </Button>
                </Modal.Footer>
              </Modal>
            )}
          </Tab.Pane>


        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export default Page3;
