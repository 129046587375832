import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { useNavigate } from 'react-router-dom'

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().required('Required'),
});

const LoginPage = () => {
  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate();
  const handleSubmit = (values, { setSubmitting }) => {

    setSubmitting(true);
    var config = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    };
    firebase.initializeApp(config);
    firebase.auth().tenantId = process.env.REACT_APP_TENANT_ID;
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function (idToken) {
          console.log(idToken);
          localStorage.setItem("token", idToken);
        }).catch(function (error) {
          // Handle error
          console.log(error);
        });
        //  document.getElementById("message").innerHTML = "Welcome, " + user.email;
      } else {
        setErrorMessage('No user signed in.')
      }
    });
    firebase.auth().signInWithEmailAndPassword(values.email, values.password)
      .then(function (userCredential) {
        // Redirect to the main application
        setSubmitting(false);
        navigate('/dashboard')
      })
      .catch(function (error) {
        // Handle login errors
        alert('Login failed. Please check your credentials.');
        console.log(error);
      });



  };

  return (
    <div className="login-container">
      <h1>Automate AI Admin Login</h1>
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form>
          <div className="form-field">
            <label htmlFor="email">Email:</label>
            <Field type="email" id="email" name="email" />
            <ErrorMessage className='text-danger' name="email" component="div" />
          </div>
          <div className="form-field">
            <label htmlFor="password">Password:</label>
            <Field type="password" id="password" name="password" />
            <ErrorMessage className='text-danger' name="password" component="div" />
          </div>
          <div className="form-field">
            <button type="submit">Login</button>
          </div>
          {errorMessage && <small className='text-danger'>{errorMessage}</small>}
        </Form>
      </Formik>
    </div>
  );
};

export default LoginPage;
