

import React, { useState, useEffect } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Tab, Nav, Table, Button, Modal } from 'react-bootstrap';
import { BsArrowLeft } from 'react-icons/bs';
import { Link } from 'react-router-dom';

const Page2 = () => {
  const  url = process.env.REACT_APP_API_URL;
  const [showMediaPopup, setShowMediaPopup] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selected, setSelected] = useState([]);
  const [table3Data, setTable3Data] = useState([]);
  const [table4Data, setTable4Data] = useState([]);
  const [table5Data, setTable5Data] = useState([]);
  const [options, setOptions] = useState();
  const idToken = localStorage.getItem('token');
  const [mode, setModeValue] = useState('');
  
  const [editingItem, setEditingItem] = useState({});
  useEffect(() => {
    fetchData();
    //  setOptions([{id:1 ,name:'Apple'}, {id:2 ,name:'Apple1'}, {id:3 ,name:'Apple2'}])
  }, []);

  const fetchData = async () => {
    const response = await fetch(url + 'admin/org/', {
      headers: {
        Authorization: `Bearer ${idToken}`,
      }
    })
    const jsonData = await response.json();
    setOptions(jsonData)
  };

  const handleInputChange = (query) => {
    // Fetch new options based on the user's query
    // (e.g., from an API)
    // Here, we'll filter the existing options locally
    const filteredOptions = options.filter((option) =>
      option.org_name.toLowerCase().includes(query.toLowerCase())
    );
    setOptions(filteredOptions);
  };

  const handleOptionSelected = (selected) => {

    setSelectedOption(selected[0]);
  };


  const fetchTemplate = async () => {
    console.log(selected[0]);
    const response3 = await fetch(url + `admin/getAllTemplate?tenant=${selectedOption.org_name}`, {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    })
    const jsonData3 = await response3.json();
    console.log(jsonData3);
    setTable3Data(jsonData3);
  };


  const statusButton = (id, active, status, org_name) => {
    let buttonVariant = '';
     buttonVariant = 'primary';
     let isButtonDisabled = false;
    return (
      <Button variant={buttonVariant} disabled={isButtonDisabled}
        onClick={() => handleStatusClick(id, org_name)}>
        status
      </Button>
    );
  }
  const renderButton = (id, active, status, org_name) => {
    let buttonVariant = '';
    let isButtonDisabled = false;

    if (status === 'SCHEDULED') {
      buttonVariant = 'primary';
      status = "TRIGGER"
    } else if (active && (status === 'DONE')) {
      buttonVariant = 'success';
      isButtonDisabled = true;
    } else if (active && status === 'NEW') {
      buttonVariant = 'danger';
      isButtonDisabled = true;
    } else {
      buttonVariant = 'secondary';
      isButtonDisabled = true;
    }

    return (
      <Button variant={buttonVariant} disabled={isButtonDisabled}
        onClick={() => handleButtonClick(id, org_name)}>
        {status}
      </Button>
    );
  };

  const savePrompt = (item,table5Data) => {
    let buttonVariant = '';
    buttonVariant = 'primary';
    let isButtonDisabled = false;
   return (
     <Button variant={buttonVariant} disabled={isButtonDisabled}
       onClick={() => savePromptClick(item,table5Data)}>
       Save Prompt
     </Button>
   );
  }

  const savePromptClick = async (item,table5Data) => {
console.log(item,table5Data);  
var data = {
   provider :table5Data[0].LLM,
   input : table5Data[0].input,
   prompt :  table5Data[0].prompt,
};
const response = await fetch(url + `admin/userllmmodel/`+ item+ `?tenant=${selectedOption.org_name}`, {
  method: 'PUT',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${idToken}`
  },
  body: JSON.stringify(data),
});

if (!response.ok) {
  throw new Error('Request failed');
}

// const responseData = await response.json();
// Handle the response data
console.log(response);
alert("Job Triggered");

  };


  const validateFile = (id, features_column,target_column , gcs_source) => {
    let buttonVariant = '';
    buttonVariant = 'primary';
    let isButtonDisabled = false;
   return (
     <Button variant={buttonVariant} disabled={isButtonDisabled}
       onClick={() => handleValidateClick(id, features_column,target_column , gcs_source)}>
       validateFile
     </Button>
   );
  }

  const handleValidateClick = async (id, features_column,target_column , gcs_source) => {
    var data = {
      source_column : JSON.parse(features_column),
      target_column :target_column,
      gcs_source : gcs_source
    };
    console.log(selected[0]);
    const response3 = await fetch(url + `admin/usercustommodel/validateFile?tenant=${selectedOption.org_name}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`
      },
      body: JSON.stringify(data),
    })
    const jsonData3 = await response3.json();
    alert(JSON.stringify(jsonData3));
   
  };


  const handlePromptChange = async (index, subIndex,event) => {
    console.log(index,subIndex,event);
   // const newData = [...data];
   table5Data[index].prompt[subIndex].prompt = event.target.value;
   setTable5Data(table5Data);
  

  };


  const handleStatusClick = async (id, org_name) => {
    // Function to be called when a button is clicked
  
    console.log(selected[0]);
    const response4 = await fetch(url + `admin/usercustommodel/templateId/${id}?tenant=${selectedOption.org_name}`, {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    })
    const jsonData4 = await response4.json();
    console.log(jsonData4);
    setTable4Data(jsonData4);

    console.log(selected[0]);
    const response5 = await fetch(url + `admin/userllmmodel/templateId/${id}?tenant=${selectedOption.org_name}`, {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    })
    const jsonData5 = await response5.json();
    console.log(jsonData5);
    setTable5Data(jsonData5);
    setShowMediaPopup(true);
  };


  const handleButtonClick = async (id, org_name) => {
    // Function to be called when a button is clicked
    var data = {
      templateId: id,
      orgName: org_name
    };
    const response = await fetch(url + 'admin/topic/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error('Request failed');
    }

    // const responseData = await response.json();
    // Handle the response data
    console.log(response);
    alert("Job Triggered");

  };

  return (
    <div className="container">
      <Link to="/dashboard">
        <BsArrowLeft /> Back
      </Link>
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <div className="form-group">

            <div className="input-group">
              <Typeahead
                id="autocomplete-example"
                labelKey="org_name" // Change 'name' to the key in your option objects that represents the displayed label
                options={options}
                placeholder="Enter Organization ..."
                onChange={handleOptionSelected}
                onInputChange={handleInputChange}
                selected={selectedOption ? [selectedOption] : []}
              />
              <div className="input-group-append">
                <button className="btn btn-primary" type="button" onClick={fetchTemplate}>
                  Get Organizations
                </button>
              </div>
            </div>
          </div>
        </div>
        

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Trigger</th>
              <th>Meaning</th>          
            </tr>
          </thead>
          <tbody>
        
              <tr>
                <td><Button  variant ='danger' disabled >DRAFT</Button></td>
                <td>New WorkFlow  </td>
              </tr>
	      <tr>
                <td><Button  variant ='success' disabled >DONE</Button></td>
                <td>WorkFlow Completed . All models within the workflow has executed successfully(disabled) </td>
              </tr>
	      <tr>
                <td><Button  variant ='primary' enabled >TRIGGERED</Button></td>
                <td>WorkFlow can be triggered to execute models within it  </td>
              </tr>     

         <tr>
                <td><Button  variant ='secondary' disabled >DONE/NEW/TRIGGERED</Button></td>
                <td>WorkFlow deleted/Inactive (disabled) </td>
              </tr>        
          </tbody>
          
     </Table>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th >ID</th>
              <th >Template Name</th>
              <th>Trigger</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {table3Data.map((item) => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item.template_name}</td>
                <td>{renderButton(item.id, item.active, item.status, selectedOption.org_name)}</td>
                <td>{statusButton(item.id, item.active, item.status, selectedOption.org_name)}</td>
              </tr>
            ))}
          </tbody>
          {showMediaPopup && (
              <Modal show={showMediaPopup} onHide={() => setShowMediaPopup(false)}>
                <Modal.Header closeButton>
                  <Modal.Title>{mode} Model State </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                <Table striped bordered hover>
          <thead>
            <tr>
              <th>Trigger</th>
              <th>Meaning</th>          
            </tr>
          </thead>
          <tbody>
        
              <tr>
                <td>NEW</td>
                <td>New Model (Model not triggered)</td>
              </tr>
		          <tr>
                <td>RUNNING</td>
                <td>Model is in a running state </td>
              </tr>
	      <tr>
                <td>DONE</td>
                <td>Model Completed </td>
              </tr>
       
          </tbody>
          
     </Table>

                  <div>Custom Model</div>
                <Table striped bordered hover>
                  <thead>
                  <tr>
                  <th >ID</th>
                  <th >Model ID</th>
                  <th>Status</th>
                  <th>Validate</th>
            </tr>
          </thead>
          <tbody>
            {table4Data.map((item) => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item.model_name}</td>
                <td>{ item.status}</td>
                <td>{validateFile(item.id, item.features_column, item.target_column, item.gcs_source)}</td>
             
              </tr>
            ))}
          </tbody>
          </Table>
              
          <div>LLM Model</div>
                <Table striped bordered hover>
                  <thead>
                  <tr>
              
              <th >Model Id</th>
              <th>Status</th>
              <th>Prompt</th>
              <th>Save</th>
            </tr>
          </thead>
          <tbody>
            {table5Data.map((item,index) => (
              <tr key={item.id}>
                
                <td>{item.model_name}</td>
                <td>{ item.status}</td>       
                <td>
                <ul>
                {item.prompt.map((subItem,subIndex) => (
                   
                  <li key={subItem.id}>{subItem.id}
                    <textarea
                 
                  onChange={(event) => handlePromptChange(index,subIndex, event)}
                >{subItem.prompt}</textarea>
                  
                  </li>
                ))}
              </ul>
                </td>
                <td>{savePrompt(item.id,table5Data)}</td>
             
              </tr>
            ))}
          </tbody>
          </Table>
                  {/* Add input fields for editing the values */}
                </Modal.Body>
                <Modal.Footer>
                <Button>Save</Button>
                  <Button variant="secondary" onClick={() => setShowMediaPopup(false)}>
                    Cancel
                  </Button>
 
                </Modal.Footer>
              </Modal>
            )}

        </Table>
      </div>
    </div>
  );
};

export default Page2;
