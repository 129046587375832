import React, { useEffect, useState } from 'react';
import { BsArrowLeft } from 'react-icons/bs';
import { Tab, Nav, Table, Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Page1 = () => {
  const [selectedTab, setSelectedTab] = useState('industry');
  const [table1Data, setTable1Data] = useState([]);
  const [table2Data, setTable2Data] = useState([]);
  const [table3Data, setTable3Data] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [editingItem, setEditingItem] = useState({});
  const [mode, setModeValue] = useState('');
  //PopUp
  const [showIndustryPopup, setShowIndustryPopup] = useState(false);
  const [showDepartmentPopup, setShowDepartmentPopup] = useState(false);
  const [showTaskPopup, setShowTaskPopup] = useState(false);


  var baseurl =process.env.REACT_APP_API_URL;
  console.log(baseurl);
  const idToken = localStorage.getItem('token');
  useEffect(() => {
    fetchData();
  }, [selectedTab]);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };


  //Button when update button is clicked 
  const updateDataInTable = (type, item, mode) => {

    setModeValue(mode);
    if (type === 'industry') {
      if (mode === 'update' || mode === 'delete') {
        setEditingItem(item);
        setShowIndustryPopup(true);
        setShowDepartmentPopup(false);
        setShowTaskPopup(false);
      } else {
        setEditingItem({ id: "", industry_name: "", gross_margin: "", inventory_turnover: "", new_inventory_turnover: "",machine_reduction: "" , active: "true" });
        setShowIndustryPopup(true);
        setShowDepartmentPopup(false);
        setShowTaskPopup(false);
      }
    }
    if (type === 'department') {
      if (mode === 'update' || mode === 'delete') {
        setEditingItem(item);
        setShowIndustryPopup(false);
        setShowDepartmentPopup(true);
        setShowTaskPopup(false);
      } else {
        setEditingItem({ id: "", department_name: "", department_description: "", active: "true", industryId: "" });
        setShowIndustryPopup(false);
        setShowDepartmentPopup(true);
        setShowTaskPopup(false);
      }

    }
    if (type === 'task') {
      if (mode === 'update' || mode === 'delete') {
        setEditingItem(item);
        setShowIndustryPopup(false);
        setShowDepartmentPopup(false);
        setShowTaskPopup(true);
      } else {
        setEditingItem({ id: "", task_name: "", task_model: "", dropdown: "", active: "true", departmentId: "", modelId: "" });
        setShowIndustryPopup(false);
        setShowDepartmentPopup(false);
        setShowTaskPopup(true);
      }
    }
  };



  //Button when save button is clicked 
  const saveUpdate = async (type, value, mode) => {
    // Perform the update logic here with the provided parameters
    if (mode === 'create') {
      await createData(type, value); //API to update data 
    } else if (mode === 'update') {
      await updateData(type, value); //API to update data 
    } else {
      await deleteData(type, value); //API to update data 
    }

    // await fetchData();
    setEditingItem({});
    if (type === 'industry') {
      await fetchData();
      setShowIndustryPopup(false);

    }
    if (type === 'department') {
      await fetchData();
      setShowDepartmentPopup(false);
    }
    if (type === 'task') {
      await fetchDepartment();
      setShowTaskPopup(false);
    }
  };
  const fetchDepartment = async () => {
    const response3 = await fetch(baseurl + `task/findById?departmentId=${inputValue}`, {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    })
    const jsonData3 = await response3.json();
    console.log(jsonData3);
    setTable3Data(jsonData3);
  };



  const fetchData = async () => {
    try {
      if (selectedTab === 'industry') {
        const response1 = await fetch(baseurl + 'industry', {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        });
        const jsonData1 = await response1.json();
        setTable1Data(jsonData1);
      } else if (selectedTab === 'department') {
        const response2 = await fetch(baseurl + 'department', {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        });
        const jsonData2 = await response2.json();
        setTable2Data(jsonData2);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };




  //API to Update 

  const updateData = async (type, data) => {
    try {
      const response = await fetch(baseurl + type + "/" + data.id, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`
        }
      });

      const result = await response.json();
      console.log('Response:', result);
    } catch (error) {
      console.error('Error:', error);
    }

  }

  //API to Create

  const createData = async (type, data) => {
    try {
      const response = await fetch(baseurl + type, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`
        }
      });
      const result = await response.json();
      console.log('Response:', result);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  //API to Delete

  const deleteData = async (type, data) => {
    try {
      const response = await fetch(baseurl + type + "/" + data.id, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`
        }
      });
      const result = await response.json();
      console.log('Response:', result);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  return (
    <div className="container">
      <Link to="/dashboard">
        <BsArrowLeft /> Back
      </Link>
      <div className="heading1">Common setting </div>
      <Tab.Container activeKey={selectedTab} onSelect={handleTabClick}>

        <Nav variant="tabs">
          <Nav.Item>
            <Nav.Link eventKey="industry">Industry</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="department">Department</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="task">Task</Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content>
          <Tab.Pane eventKey="industry">
            <h4>Industry</h4>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th >ID</th>
                  <th >Industry Name</th>
                  <th >Active</th>
                  <th >Gross Margin</th>
                  <th >Inventory Turnover</th>
                  <th >New Inventory Turnover</th>
                  <th >Machine Reduction</th>
                  <th >Update</th>
                  <th >Delete</th>
                </tr>
              </thead>
              <tbody>
                {table1Data.map((item) => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.industry_name}</td>
                    <td>{item.active}</td>
                    <td>{item.gross_margin}</td>
                    <td>{item.inventory_turnover}</td>
                    <td>{item.new_inventory_turnover}</td>
                    <td>{item.machine_reduction}</td>
                    <td>
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() => updateDataInTable("industry", item, "update")}
                      >
                        Update
                      </button>
                    </td>
                    <td>
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() => updateDataInTable("industry", item, "delete")}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td>
                    <Button id="department-add" variant="primary"
                      onClick={() => updateDataInTable("industry", {}, "create")}
                    >Add Row</Button>
                  </td>
                </tr>
              </tfoot>
            </Table>
            {showIndustryPopup && (
              <Modal show={showIndustryPopup} onHide={() => setShowIndustryPopup(false)}>
                <Modal.Header closeButton>
                  <Modal.Title>{mode} Item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>ID: {editingItem.id}</p>
                  <p>Industry Name: <input type="text" value={editingItem.industry_name}
                    onChange={(e) =>
                      setEditingItem({ id: editingItem.id, industry_name: e.target.value, active: editingItem.active, gross_margin: editingItem.gross_margin, inventory_turnover: editingItem.inventory_turnover, new_inventory_turnover: editingItem.new_inventory_turnover ,machine_reduction: editingItem.machine_reduction })
                    } />
                  </p>

                  <p>Gross Margin:
                    <input type="number" value={editingItem.gross_margin}
                      onChange={(e) =>
                        setEditingItem({ id: editingItem.id, industry_name: editingItem.industry_name, active: editingItem.active, gross_margin: e.target.value, inventory_turnover: editingItem.inventory_turnover, new_inventory_turnover: editingItem.new_inventory_turnover ,machine_reduction: editingItem.machine_reduction })
                      } />
                  </p>
                  <p>Inventory Turnover:  <input type="number" value={editingItem.inventory_turnover}
                    onChange={(e) =>
                      setEditingItem({ id: editingItem.id, industry_name: editingItem.industry_name, active: editingItem.active, gross_margin: editingItem.gross_margin, inventory_turnover: e.target.value, new_inventory_turnover: editingItem.new_inventory_turnover ,machine_reduction: editingItem.machine_reduction  })
                    }
                  /></p>
                  <p>New Inventory Turnover:  <input type="number" value={editingItem.new_inventory_turnover}
                    onChange={(e) =>
                      setEditingItem({ id: editingItem.id, industry_name: editingItem.industry_name, active: editingItem.active, gross_margin: editingItem.gross_margin, inventory_turnover: editingItem.inventory_turnover, new_inventory_turnover: e.target.value , machine_reduction: editingItem.machine_reduction })
                    }
                  /></p>

                <p>Machine Reduction:  <input type="number" value={editingItem.machine_reduction}
                    onChange={(e) =>
                      setEditingItem({ id: editingItem.id, industry_name: editingItem.industry_name, active: editingItem.active, gross_margin: editingItem.gross_margin, inventory_turnover: editingItem.inventory_turnover, new_inventory_turnover: editingItem.new_inventory_turnover,machine_reduction: e.target.value })
                    }
                  /></p>
                  <p>Active <input type="text" value={editingItem.active}
                    onChange={(e) =>
                      setEditingItem({ id: editingItem.id, industry_name: editingItem.industry_name, active: e.target.value, gross_margin: editingItem.gross_margin, inventory_turnover: editingItem.inventory_turnover, new_inventory_turnover: editingItem.new_inventory_turnover })
                    } />
                  </p>
                  {/* Add input fields for editing the values */}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => setShowIndustryPopup(false)}>
                    Cancel
                  </Button>
                  <Button variant="primary" onClick={() => saveUpdate("industry", editingItem, mode)}>
                    {mode}
                  </Button>
                </Modal.Footer>
              </Modal>
            )}

          </Tab.Pane>
          <Tab.Pane eventKey="department">
            <h3>Department *(102 common department)</h3>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Department Name</th>
                  <th>Department Description</th>
                  <th>Industry ID</th>
                  <th >Update</th>
                  <th >Delete</th>
                </tr>
              </thead>
              <tbody>
                {table2Data.map((item) => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.department_name}</td>
                    <td>{item.department_description}</td>
                    <td>{item.industryId}</td>
                    <td>
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() => updateDataInTable("department", item, "update")}
                      >
                        Update
                      </button>
                    </td>
                    <td>
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() => updateDataInTable("department", item, "delete")}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td>
                    <Button id="department-add" variant="primary"
                      onClick={() => updateDataInTable("department", {}, "create")}
                    >Add Row</Button>
                  </td>
                </tr>
              </tfoot>
            </Table>
            {showDepartmentPopup && (

              <Modal show={showDepartmentPopup} onHide={() => setShowDepartmentPopup(false)}>
                <Modal.Header closeButton>
                  <Modal.Title>{mode} Item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>ID: {editingItem.id}</p>
                  <p>Department Name:
                    <textarea type="text" value={editingItem.department_name}
                      onChange={(e) =>
                        setEditingItem({ id: editingItem.id, department_name: e.target.value, department_description: editingItem.department_description, active: editingItem.active, industryId: editingItem.industryId })
                      } />
                  </p>
                  <p>Department Description:
                    <textarea type="text" value={editingItem.department_description}
                      onChange={(e) =>
                        setEditingItem({ id: editingItem.id, department_name: editingItem.department_name, department_description: e.target.value, active: editingItem.active, industryId: editingItem.industryId })
                      } />
                  </p>

                  <p>Industry:
                    <input type="text" value={editingItem.industryId}
                      onChange={(e) =>
                        setEditingItem({ id: editingItem.id, department_name: editingItem.department_name, department_description: editingItem.department_description, active: editingItem.active, industryId: e.target.value })
                      } />
                  </p>


                  <p>Active <input type="text" value={editingItem.active}
                    onChange={(e) =>
                      setEditingItem({ id: editingItem.id, department_name: editingItem.department_name, department_description: editingItem.department_description, active: e.target.value, industryId: editingItem.industryId })
                    } />
                  </p>


                  {/* Add input fields for editing the values */}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => setShowDepartmentPopup(false)}>
                    Cancel
                  </Button>
                  <Button variant="primary" onClick={() => saveUpdate("department", editingItem, mode)}>
                    {mode}
                  </Button>
                </Modal.Footer>
              </Modal>
            )}
          </Tab.Pane>

          <Tab.Pane eventKey="task">
            <h3>Task</h3>
            <div className="row">
              <label htmlFor="inputData">Enter Department</label>
              <div className="col-sm-6 ">

                <input
                  type="text"
                  className="form-control"
                  id="inputData"
                  value={inputValue}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-sm-6 ">
                <button className="btn btn-primary" onClick={fetchDepartment}>
                  Click
                </button>
              </div>

            </div>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th >ID</th>
                  <th >Task Name</th>
                  <th >Task Model</th>
                  <th >Drop Down</th>
                  <th >Active </th>
                  <th >Department ID</th>
                  <th >Model ID</th>
                  <th>Update</th>
                  <th>Delete</th>

                </tr>
              </thead>
              <tbody>
                {table3Data.map((item) => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.task_name}</td>
                    <td>{item.task_model}</td>
                    <td>{item.dropdown}</td>
                    <td>{item.active}</td>
                    <td>{item.departmentId}</td>
                    <td>{item.modelId}</td>
                    <td>
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() => updateDataInTable("task", item, "update")}
                      >
                        Update
                      </button>
                    </td>
                    <td>
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={() => updateDataInTable("task", item, "delete")}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td>
                    <Button id="department-add" variant="primary"
                      onClick={() => updateDataInTable("task", {}, "create")}
                    >Add Row</Button>
                  </td>
                </tr>
              </tfoot>
            </Table>
            {showTaskPopup && (
              <Modal show={showTaskPopup} onHide={() => setShowTaskPopup(false)}>
                <Modal.Header closeButton>
                  <Modal.Title>{mode} Item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>ID: {editingItem.id}</p>
                  <p>Task Name:
                    <input type="text" value={editingItem.task_name}
                      onChange={(e) =>
                        setEditingItem({ id: editingItem.id, task_name: e.target.value, task_model: editingItem.task_model, dropdown: editingItem.dropdown, active: editingItem.active, departmentId: editingItem.departmentId, modelId: editingItem.modelId })
                      } />
                  </p>

                  <p>Task Model:
                    <input type="text" value={editingItem.task_model}
                      onChange={(e) =>
                        setEditingItem({ id: editingItem.id, task_name: editingItem.task_name, task_model: e.target.value, dropdown: editingItem.dropdown, active: editingItem.active, departmentId: editingItem.departmentId, modelId: editingItem.modelId })
                      } />
                  </p>

                  <p>Drop Down:
                    <input type="text" value={editingItem.dropdown}
                      onChange={(e) =>
                        setEditingItem({ id: editingItem.id, task_name: editingItem.task_name, task_model: editingItem.task_model, dropdown: e.target.value, active: editingItem.active, departmentId: editingItem.departmentId, modelId: editingItem.modelId })
                      } />
                  </p>
                  <p>Active
                    <input type="text" value={editingItem.active}
                      onChange={(e) =>
                        setEditingItem({ id: editingItem.id, task_name: editingItem.task_name, task_model: editingItem.task_model, dropdown: editingItem.dropdown, active: e.target.value, departmentId: editingItem.departmentId, modelId: editingItem.modelId })
                      } />
                  </p>

                  <p>Department Id
                    <input type="text" value={editingItem.departmentId}
                      onChange={(e) =>
                        setEditingItem({ id: editingItem.id, task_name: editingItem.task_name, task_model: editingItem.task_model, dropdown: editingItem.dropdown, active: editingItem.active, departmentId: e.target.value, modelId: editingItem.modelId })
                      } />
                  </p>

                  <p>Model Id
                    <input type="text" value={editingItem.modelId}
                      onChange={(e) =>
                        setEditingItem({ id: editingItem.id, task_name: editingItem.task_name, task_model: editingItem.task_model, dropdown: editingItem.dropdown, active: editingItem.active, departmentId: editingItem.departmentId, modelId: e.target.value })
                      } />
                  </p>


                  {/* Add input fields for editing the values */}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => setShowTaskPopup(false)}>
                    Cancel
                  </Button>
                  <Button variant="primary" onClick={() => saveUpdate("task", editingItem, mode)}>
                    {mode}
                  </Button>
                </Modal.Footer>
              </Modal>
            )}

          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export default Page1;
